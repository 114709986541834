import UserModel from "../../model/UserModel"
import "./fuelMeter.css"
import React from "react"

export default class FuelMeter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      round: 0,
      fuelLevel: this.props.fuelLevel,
    }

    this.userModel = new UserModel()

    this.fetchFuelLevel = this.fetchFuelLevel.bind(this)
    this.animateNeedle = this.animateNeedle.bind(this)
  }

  componentDidMount() {
    this.fetchFuelLevel()
    this.animateNeedle()
  }

  async fetchFuelLevel() {
    const fuelLevel = await this.userModel.FetchUserFuelLevel(
      this.state.fuelLevel
    )
    this.setState({ fuelLevel: fuelLevel, round: 0 }, () => {
      setTimeout(() => {
        this.fetchFuelLevel()
      }, 1000)
    })
  }

  animateNeedle() {
    if (this.state.round >= 20) {
      this.setState({ round: 0 }, () => {
        setTimeout(() => {
          this.animateNeedle()
        }, 25)
      })
    } else {
      let constant = 0.003
      let newFuelLevel = this.state.fuelLevel
      if (this.state.round < 5) {
        newFuelLevel = this.state.fuelLevel + constant
      } else if (this.state.round < 15) {
        newFuelLevel = this.state.fuelLevel - constant
      } else if (this.state.round < 20) {
        newFuelLevel = this.state.fuelLevel + constant
      }
      this.setState(
        { fuelLevel: newFuelLevel, round: this.state.round + 1 },
        () => {
          setTimeout(() => {
            this.animateNeedle()
          }, 25)
        }
      )
    }
  }

  render() {
    let fuelLevel = this.state.fuelLevel / 2
    if (fuelLevel === undefined) {
      fuelLevel = 0.02
    } else if (fuelLevel < 0.02) {
      fuelLevel = 0.02
    } else if (fuelLevel > 0.48) {
      fuelLevel = 0.48
    }

    return (
      <div className="gauge">
        <div className="gauge__body">
          <div className="gauge__fill"></div>
          <div className="gauge__cover">
            <span>E - F</span>
          </div>
          <div
            className="gauge__needle"
            style={{ transform: `rotate(${fuelLevel}turn)` }}></div>
        </div>
      </div>
    )
  }
}
