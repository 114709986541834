import LocalDataModel from "./LocalDataModel"
import SettingsModel from "./SettingsModel"
import ConfigModel from "./ConfigModel"

export default class UserModel {
    user = null

    constructor() {
        this.localDataModel = new LocalDataModel()
        this.settingsModel = new SettingsModel()
        this.configModel = new ConfigModel()
    }

    GetUserFromLocal() {
        if (this.user === null) {
            let data = this.localDataModel.GetLocalData()
            this.user = JSON.parse(data.User)
        }

        return this.user
    }

    async SetAuthUser(user) {
        let data = this.localDataModel.GetLocalData()
        this.user = JSON.parse(data.User)
        let token = user.data.api_token

        let userProfileData = await this.FetchUser(token)
        let userStatics = this.user.data.userStatics
        this.user.token = token
        this.user.data = user.data
        this.user.data.userProfileStatics = userProfileData
        this.user.data.userStatics = userStatics
        data.User = JSON.stringify(this.user)
        this.localDataModel.SetLocalData(data)

        return this.user
    }

    async GetUser() {
        let data = this.localDataModel.GetLocalData()
        this.user = JSON.parse(data.User)
        let userProfileData = await this.FetchUser()
        this.user.data.userProfileStatics = userProfileData
        data.User = JSON.stringify(this.user)
        this.localDataModel.SetLocalData(data)

        return this.user
    }

    CalculateScore(score, totalQuestions) {
        const systemconfig = this.settingsModel.settings.systemConfig
        let correctAnswer = score
        let incorrectAnswer = totalQuestions - score
        let correctAnswerScore = correctAnswer * systemconfig.score
        let incorrectAnswerScore = incorrectAnswer * 1
        let finalScore = correctAnswerScore - incorrectAnswerScore

        return finalScore
    }

    CalculateCoins(score, totalQuestions) {
        const systemconfig = this.settingsModel.settings.systemConfig
        let percentage = (score * 100) / totalQuestions // 400/5 = 80
        let earnedCoins = 0
        if (percentage >= Number(systemconfig.maximum_coins_winning_percentage)) {
            //80 >= 30
            earnedCoins = systemconfig.maximum_winning_coins // 4
        } else {
            earnedCoins = systemconfig.maximum_winning_coins - (Number(systemconfig.maximum_coins_winning_percentage) - percentage) / 10 // 4 - (30 - 80) / 10 => 34/10 => 5.4
        }
        if (earnedCoins < 0) {
            earnedCoins = 0
        }

        return earnedCoins
    }

    async AuthenticateUser(token) {
        let url = `${this.configModel.api}authenticate_thirdparty_jwt`
        let data = {
            "access_key": "8525",
            "token": token
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            if(!response_data.error) {
                let user = await this.SetAuthUser(response_data)
                return user
            } else {
                return false
            }
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async SetUserCoinScore(coins, score, type, title, status) {
        let url = `${this.configModel.api}set_user_coin_score`
        let data = {
            "access_key": "8525",
            "coins": coins,
            "score": score,
            "type": type,
            "title": title,
            "status": status
        }
        let userData = this.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async GetUserCoinScore() {
        let url = `${this.configModel.api}get_user_coin_score`
        let data = {
            "access_key": "8525"
        }
        let userData = this.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async SetUserStatistics(questions_answered, correct_answers, category_id, percentage) {
        let url = `${this.configModel.api}set_users_statistics`
        let data = {
            "access_key": "8525",
            "questions_answered": questions_answered,
            "correct_answers": correct_answers,
            "category_id": category_id, //(id of category which user played)
            "ratio": percentage
        }
        let userData = this.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async GetUserStatistics() {
        let url = `${this.configModel.api}get_users_statistics`
        let data = {
            "access_key": "8525"
        }
        let userData = this.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data.data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async FetchUser(token) {
        let url = `${this.configModel.api}get_user_by_id`
        let data = {
            "access_key": "8525"
        }
        let userData = this.GetUserFromLocal()
        let authorization = userData.data.api_token
        if (token) {
            authorization = token
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data.data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }
    
    async FetchUserProfile(userId, token) {
        let url = `${this.configModel.api}get_user_data_by_id`
        let data = {
            "access_key": "8525",
            "user_id": userId
        }
        let userData = this.GetUserFromLocal()
        let authorization = userData.data.api_token
        if (token) {
            authorization = token
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data.data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }
    
    async FetchUserFuelLevel(fuelLevel, token) {
        let url = `${this.configModel.api}get_student_fuel_level`
        let data = {
            "access_key": "8525",
            "fuel_level": fuelLevel
        }
        let userData = this.GetUserFromLocal()
        let authorization = userData.data.api_token
        if (token) {
            authorization = token
        }

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data.data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }

    async UpdateProfile(studentDOB, schoolName, className) {
        let url = `${this.configModel.api}update_profile`
        let data = {
            "access_key": "8525",
            "student_dob": studentDOB,
            "school_name": schoolName,
            "class_name": className,
        }
        let userData = this.GetUserFromLocal()
        let authorization = userData.data.api_token

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${authorization}`,
                },
                body: JSON.stringify(data)
            })

            const response_data = await response.json()
            return response_data
        } catch (err) {
            console.error("post request failed ", err)
            return err
        }
    }
}