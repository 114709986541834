import "./ContestLeaderBoard.css"
import React from "react"
import LeaderBoardModel from "../../../model/LeaderBoardModel"

// Image imports
import pos1Ico from "./../../../assets/img/pos1-ico.svg"
import pos2Ico from "./../../../assets/img/pos2-ico.svg"
import pos3Ico from "./../../../assets/img/pos3-ico.svg"
import lbUserIco from "./../../../assets/img/lb-user-ico.svg"
import lbCoinIcon from "./../../../assets/img/lb-coin-icon.svg"
import UserModel from "../../../model/UserModel"

export default class ContestLeaderBoard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      contest: props.contest,
      leaderBoard: [],
    }

    this.leaderBoardModel = new LeaderBoardModel()
    this.userModel = new UserModel()

    this.fetchContestLeaderBoard = this.fetchContestLeaderBoard.bind(this)
    this.fetchCurrentUser = this.fetchCurrentUser.bind(this)
    this.getClassByIndex = this.getClassByIndex.bind(this)
  }

  componentDidMount() {
    this.fetchContestLeaderBoard()
    this.fetchCurrentUser()
  }

  async fetchContestLeaderBoard() {
    const leaderBoard = await this.leaderBoardModel.GetContestLeaderBoard(
      this.state.contest.id
    )
    this.setState({ leaderBoard: leaderBoard })
  }

  async fetchCurrentUser() {
    let currUser = await this.userModel.GetUser()
    this.setState({ currUser: currUser })
  }

  getClassByIndex(index) {
    let currUser = this.state.currUser
    let usrId = currUser?.data?.id

    let highlight = ""
    if (this.state.leaderBoard[index].user_id === usrId) {
      highlight = "highlight"
    }

    if (index % 4 === 0) {
      return `yellow ${highlight}`
    } else if (index % 4 === 1) {
      return `blue ${highlight}`
    } else if (index % 4 === 2) {
      return `red ${highlight}`
    } else {
      return `brown ${highlight}`
    }
  }

  render() {
    return (
      <div
        className="leader-board"
        style={{ background: "rgb(255 255 255 / 0%)" }}>
        <h5 className="mb-3 ps-1">{this.state.contest.name} - Leader board</h5>

        <ul>
          {this.state.leaderBoard.map((userRank, index) => (
            <li key={index} className={this.getClassByIndex(index)}>
              {index === 0 && <img alt="" className="pos-ico" src={pos1Ico} />}
              {index === 1 && <img alt="" className="pos-ico" src={pos2Ico} />}
              {index === 2 && <img alt="" className="pos-ico" src={pos3Ico} />}

              <img alt="" className="user-ico" src={lbUserIco} />
              <p className="username">{userRank.name}</p>
              <div className="score">
                <img alt="" src={lbCoinIcon} />
                <span>{userRank.score}</span>
              </div>
            </li>
          ))}
        </ul>

        <button
          type="button"
          className="play-btn mt-5"
          onClick={this.props.onHideLeaderBoard}>
          Go Back
        </button>
      </div>
    )
  }
}
