import GoBackButton from "../../common/goBackButton/GoBackButton"
import "./QuizSummary.css"
import React from "react"

export default class QuizSummary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contest: props.contest,
            is_accepted: false ,
            ch_accepted: true ,
        }


        this.toggleAccept = this.toggleAccept.bind(this)
    }

    toggleAccept() {
        this.setState({ is_accepted: !this.state.is_accepted })
        this.setState({ ch_accepted: !this.state.ch_accepted })
    }

    render() {
        return (
            <div className="quiz-play">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="name">
                                <GoBackButton onClick={this.props.onGoBack}></GoBackButton>
                                {this.state.contest.name}
                            </div>
                        </div>
                        <div className="col-md-12">
                            <p>
                                <img className="quiz-img mb-3 me-3 pull-left" src={this.state.contest.image} alt="Quiz" style={{ width: 'auto', height: '190px' }} />
                                {this.state.contest.description}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group tc">
                                <input type="checkbox" className="form-check-input" id="tc" checked={this.state.ch_accepted} value={this.state.ch_accepted} onChange={this.toggleAccept} />
                                <label className="form-check-label" htmlFor="tc"><a href="contestsTC.html" target='_blank'>Terms & Conditions</a></label>
                            </div>
                            <button type="button" className="nxt-btn" onClick={() => this.props.onPlayContest(this.state.contest)} disabled={!this.state.ch_accepted}>START</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}