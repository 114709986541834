import "./Congrats.css"
import React from "react"
import Confetti from "react-confetti"

// Image imports
import congrats from "./../../../assets/img/congrats.gif"

export default class Congrats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      attainedPoints: props.attainedPoints,
    }
  }

  render() {
    return (
      <>
        <Confetti className="confetti-container" />
        <div className="congrats-shadow"></div>
        <div className="congrats">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="congrts_bg">
                  <img
                    src={congrats}
                    className="card-img-top"
                    alt="Coin bucket"
                  />
                  {/* <h5 className="card-title">Congratulations!</h5> */}
                  <div className="congrts_cont">
                    <p>
                      You have received <b>{this.state.attainedPoints}</b>{" "}
                      points
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
