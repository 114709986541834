import "./readComics.css"
import React from "react"
import Modal from "react-bootstrap/Modal"
import IntroVideoPlayer from "../IntroVideoPlayer/IntroVideoPlayer"

// Image imports
import readComicsImg from "../../assets/img/read-comics-img.svg"
import playLearnEarnIcoImg from "../../assets/img/play-learn-earn-ico.gif"

export default class ReadComics extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showComics: false,
    }

    this.toggleReadComic = this.toggleReadComic.bind(this)
  }

  componentDidUpdate() {}

  toggleReadComic() {
    this.setState({ showComics: !this.state.showComics })
  }

  render() {
    return (
      <>
        <div className="content">
          {/* <div style={{ display: this.state.showComics ? "none" : "block" }}> */}
          <div className="left">
            <div className="left">
              <img alt="" src={readComicsImg} />
            </div>
            <div className="right space_new">
              <span className="orange">read</span>
              <span className="brown">comic</span>
            </div>
            <h2 onClick={this.toggleReadComic}>The STOGO Mission #1</h2>
          </div>

          <div className="right">
            <div className="end-container">
              <a
                href="!#"
                data-bs-toggle="modal"
                data-bs-target="#GamesPuzzles">
                <div className="play-learn-earn">
                  <div className="cont">
                    <span className="white">play !</span>
                    <span className="yellow">&</span>
                    <span className="white">learn !</span>
                  </div>
                  <div className="ico">
                    <img alt="" src={playLearnEarnIcoImg} />
                  </div>
                </div>
              </a>
            </div>
          </div>

          <Modal
            show={this.state.showComics}
            onHide={() => this.toggleReadComic()}
            dialogClassName="modal-90w"
            backdrop="static"
            keyboard={false}
            size="xl"
            centered>
            <Modal.Header closeButton>
              <Modal.Title>STOGO Comic</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="modal-body">
                <IntroVideoPlayer></IntroVideoPlayer>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </>
    )
  }
}
