import React from "react"

// Image imports
import iconVerifiedImg from "../../../../assets/img/icon-verified.png"
import likeImg from "../../../../assets/img/like-01.svg"
import loveImg from "../../../../assets/img/love-01.svg"
import wowImg from "../../../../assets/img/wow-01.svg"
import partyPoperImg from "../../../../assets/img/party-poper-01.svg"
import clapImg from "../../../../assets/img/clap-01.svg"

export default class Feed extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      feed: props.feed,
      like: false,
      love: false,
      wow: false,
      celebrate: false,
      clap: false,
    }

    this.getReactionCount = this.getReactionCount.bind(this)
    this.setReactions = this.setReactions.bind(this)
    this.getFeedTitle = this.getFeedTitle.bind(this)
  }

  componentDidMount() {
    this.setReactions()
  }

  getReactionCount() {
    let count = 0
    if (this.state.feed.reactions) {
      count = this.state.feed.reactions.length
    }

    return count
  }

  getFeedTitle() {
    if (this.state.feed.type === "image") {
      return (
        <>
          {this.state.feed.contest.name}
          <small> ({this.state.feed.title})</small>
        </>
      )
    } else {
      return this.state.feed.title
    }
  }

  setReactions() {
    if (this.state.feed.reactions) {
      this.state.feed.reactions.forEach((reaction) => {
        if (reaction.reaction === "like") {
          this.setState({ like: true })
        } else if (reaction.reaction === "love") {
          this.setState({ love: true })
        } else if (reaction.reaction === "wow") {
          this.setState({ wow: true })
        } else if (reaction.reaction === "party_popper") {
          this.setState({ celebrate: true })
        } else if (reaction.reaction === "clapping_hand") {
          this.setState({ clap: true })
        }
      })
    }
  }

  formatNumber(number) {
    return number.toLocaleString("en-US", {
      notation: "compact",
      compactDisplay: "short",
    })
  }

  render() {
    return (
      <div className="row mb-4">
        <div className="col-lg-6">
          {(() => {
            if (this.state.feed.type === "image") {
              return (
                <div className="feeds-image">
                  <img
                    src={this.state.feed.url}
                    alt={this.state.feed.title}
                    className="img-fluid"></img>
                </div>
              )
            }
          })()}

          {(() => {
            if (this.state.feed.type === "video") {
              return (
                <div className="feeds-video-player">
                  {(() => {
                    if (this.state.feed.is_accepted) {
                      return (
                        <span className="approved_tick">
                          <img
                            src={iconVerifiedImg}
                            title="Approved"
                            alt="Approved"
                          />
                        </span>
                      )
                    }
                  })()}

                  <video width="100%" height="294" controls>
                    <source src={this.state.feed.url}></source>
                    Your browser does not support the video tag.
                  </video>
                </div>
              )
            }
          })()}
        </div>

        <div className="col-lg-6">
          <div className="prof_details">
            <h3>{this.getFeedTitle()}</h3>
            <p>{this.state.feed.description}</p>

            <div className="elements">
              {(() => {
                if (this.getReactionCount() > 0) {
                  let reactions = []
                  if (this.state.like) {
                    reactions.push(
                      <span className="like">
                        <img src={likeImg} alt="Like" />
                      </span>
                    )
                  }
                  if (this.state.love) {
                    reactions.push(
                      <span className="love">
                        <img src={loveImg} alt="Heart" />
                      </span>
                    )
                  }
                  if (this.state.wow) {
                    reactions.push(
                      <span className="like">
                        <img src={wowImg} alt="Wow" />
                      </span>
                    )
                  }
                  if (this.state.celebrate) {
                    reactions.push(
                      <span className="like">
                        <img src={partyPoperImg} alt="Celebrate" />
                      </span>
                    )
                  }
                  if (this.state.clap) {
                    reactions.push(
                      <span className="like">
                        <img src={clapImg} alt="Clap" />
                      </span>
                    )
                  }

                  return reactions
                } else {
                  return (
                    <span className="like">
                      <img src={likeImg} alt="Like" />
                    </span>
                  )
                }
              })()}
              <div className="rec_count">
                <span>{this.formatNumber(this.getReactionCount())}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
