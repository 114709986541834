import React from "react"
import LeaderBoardModel from "./../../../model/LeaderBoardModel"
import UserModel from "../../../model/UserModel"
import "./LeaderBoard.css"

// Import images
import closeBtn from "./../../../assets/img/close-btn.svg"
import lbCoinIcon from "./../../../assets/img/lb-coin-icon.svg"
import lbUserIco from "./../../../assets/img/lb-user-ico.svg"
import pos1Ico from "./../../../assets/img/pos1-ico.svg"
import pos2Ico from "./../../../assets/img/pos2-ico.svg"
import pos3Ico from "./../../../assets/img/pos3-ico.svg"

export default class LeaderBoard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      type: this.props.type,
      allTimeLeaders: this.props.allTimeLeaders,
      allTimeCurrentUserRank: this.props.allTimeCurrentUserRank,
      otherUsersRank: [],
      myRank: [],
      showMyRank: false,
      currentUser: {},
    }
    this.leaderBoardModel = new LeaderBoardModel()
    this.userModel = new UserModel()

    this.getClassByIndex = this.getClassByIndex.bind(this)
    this.toggleLeaderBoard = this.toggleLeaderBoard.bind(this)
    this.fetchCurrentUser = this.fetchCurrentUser.bind(this)
    this.getClassByIndex = this.getClassByIndex.bind(this)
  }

  componentDidMount() {
    this.fetchCurrentUser()
    this.fetchLeaderBoard()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.type !== "weekly") {
      //Change in props
      return {
        ...state,
        type: props.type,
        allTimeLeaders: props.allTimeLeaders,
        allTimeCurrentUserRank: props.allTimeCurrentUserRank,
      }
    } else {
      return {
        ...state,
        type: props.type,
      }
    }
  }

  fetchCurrentUser() {
    const user = this.userModel.GetUserFromLocal()
    this.setState({ currentUser: user.data })
  }

  async fetchLeaderBoard() {
    try {
      const leaderBoardModel = new LeaderBoardModel()
      const monthlyLeaderBoard = await leaderBoardModel.GetWeeklyLeaderBoard()
      const fetchedOtherUsersRank = monthlyLeaderBoard.data.other_users_rank
      const myRank = monthlyLeaderBoard.data.my_rank

      // Check if fetchedOtherUsersRank is not undefined before setting the state
      if (fetchedOtherUsersRank !== undefined) {
        this.setState({
          otherUsersRank: fetchedOtherUsersRank,
          myRank: myRank,
        })
      }
      // Process the data or update the state as needed
    } catch (error) {
      console.error("Error fetching monthly leader board data:", error)
    }
  }

  toggleLeaderBoard() {
    this.setState({
      showMyRank: !this.state.showMyRank,
    })
  }

  getClassByIndex(index) {
    let className = ""
    let leaders = this.state.otherUsersRank
    // let myRank = this.state.myRank
    if (this.state.type !== "weekly") {
      leaders = this.state.allTimeLeaders
      // myRank = this.state.allTimeCurrentUserRank
    }

    if (
      leaders[index]?.user_id === this.state.currentUser.id ||
      this.state.showMyRank
    ) {
      className += "highlight "
    }

    if (index % 4 === 0) {
      className += "yellow"
    } else if (index % 4 === 1) {
      className += "blue"
    } else if (index % 4 === 2) {
      className += "red"
    } else {
      className += "brown"
    }

    return className
  }

  getClassByIndexForMyRank(index) {
    let className = ""
    let leaders = this.state.myRank
    if (this.state.type !== "weekly") {
      leaders = this.state.allTimeCurrentUserRank
    }

    if (leaders[index]?.user_id === this.state.currentUser.id) {
      className += "highlight "
    }

    if (index % 4 === 0) {
      className += "yellow"
    } else if (index % 4 === 1) {
      className += "blue"
    } else if (index % 4 === 2) {
      className += "red"
    } else {
      className += "brown"
    }

    return className
  }

  render() {
    let otherUsersRank = this.state.otherUsersRank
    let myRank = this.state.myRank
    if (this.state.type !== "weekly") {
      otherUsersRank = this.state.allTimeLeaders
      myRank = this.state.allTimeCurrentUserRank
    }
    return (
      <div
        className="modal fade"
        id="LeaderBoard"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="LeaderBoard"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content leader-board">
            <a
              href="!#"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close">
              <img alt="" src={closeBtn} />
            </a>
            <div className="pop-banner leader-board">
              <h1>Leader Board</h1>
            </div>
            <div className="modal-body">
              <div className="content">
                <h5>Contests</h5>

                <div className="filter-area" onChange={this.toggleLeaderBoard}>
                  <input
                    type="radio"
                    name="filter"
                    value="leader"
                    id="leader"
                    className="visually-hidden"
                    defaultChecked
                  />
                  {/* <input type="radio" name="filter" value="leader" id="leader" className="visually-hidden" defaultChecked
                    onClick="scrollToPosition(1)" /> */}
                  <label htmlFor="leader">
                    <span>Leader</span>
                  </label>
                  <input
                    type="radio"
                    name="filter"
                    value="my-position"
                    id="my-position"
                    className="visually-hidden"
                  />
                  {/* <input type="radio" name="filter" value="my-position" id="my-position" className="visually-hidden"
                    onClick="scrollToPosition(6)" /> */}
                  <label htmlFor="my-position">
                    <span>My Position</span>
                  </label>
                </div>

                <div className="scroller-container">
                  <div className="scroller-area" id="scroller">
                    <div>
                      {(() => {
                        if (!this.state.showMyRank) {
                          return (
                            <ul>
                              {otherUsersRank.map((userRank, index) => (
                                <li
                                  key={index}
                                  className={this.getClassByIndex(index)}>
                                  {(() => {
                                    if (index > 2) {
                                      return (
                                        <img
                                          alt=""
                                          className="user-ico"
                                          src={lbUserIco}
                                        />
                                      )
                                    } else {
                                      if (index === 0) {
                                        return (
                                          <img
                                            alt=""
                                            className="pos-ico"
                                            src={pos1Ico}
                                          />
                                        )
                                      } else if (index === 1) {
                                        return (
                                          <img
                                            alt=""
                                            className="pos-ico"
                                            src={pos2Ico}
                                          />
                                        )
                                      } else if (index === 2) {
                                        return (
                                          <img
                                            alt=""
                                            className="pos-ico"
                                            src={pos3Ico}
                                          />
                                        )
                                      } else {
                                        return null
                                      }
                                    }
                                  })()}
                                  <p className="username">
                                    <a href={`/profile/${userRank.user_id}`}>
                                      {userRank.name}
                                    </a>
                                  </p>
                                  <div className="score">
                                    <img alt="" src={lbCoinIcon} />
                                    <span>{userRank.score}</span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )
                        } else {
                          return (
                            <ul>
                              {myRank.map((userRank, index) => (
                                <li
                                  key={index}
                                  className={this.getClassByIndexForMyRank(
                                    index
                                  )}>
                                  {/* <div className="rankNo">
                                    {userRank.user_rank}
                                  </div> */}
                                  <img
                                    alt=""
                                    className="user-ico"
                                    src={lbUserIco}
                                  />
                                  <p className="username">
                                    <a href={`/profile/${userRank.user_id}`}>
                                      <span className="rank">
                                        {userRank.user_rank}
                                      </span>
                                      {userRank.name}
                                    </a>
                                  </p>
                                  <div className="score">
                                    <img alt="" src={lbCoinIcon} />
                                    <span>{userRank.score}</span>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          )
                        }
                      })()}
                    </div>
                  </div>
                </div>
                <div className="spacer30"></div>
                <button
                  className="play-btn"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#LiveContests">
                  PLAY CONTEST
                </button>
                <button
                  className="play-btn"
                  data-bs-dismiss="modal"
                  data-bs-toggle="modal"
                  data-bs-target="#ChallengeMonth">
                  PLAY CHALLENGE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
