import "./Contest.css"
import React from "react"
import Button from "./Button"

export default class Contest extends React.Component {
  constructor(param) {
    super(param)
    this.state = {
      contest: param.contest,
      type: param.type,
    }
  }

  componentWillUnmount() {
    this.setState({})
  }

  render() {
    let entryPrize = this.state.contest.entry_prize
    let participants = this.state.contest.participants
    let startDate = this.state.contest.start_date
    let endDate = this.state.contest.end_date
    let ageGroup = this.state.contest.age_group + " yrs"

    if (this.state.contest.is_default_contest === "1") {
      entryPrize = "nil"
      participants = "nil"
      startDate = "nil"
      endDate = "nil"
      ageGroup = "nil"
    }

    let str = (
      <>
        <div className="contest swiper-slide">
          <img src={this.state.contest.image} alt={this.state.contest.name} />
          <div className="cont">
            <h3>{this.state.contest.name}</h3>
            <p>
              <span>Participation Prize:</span> {entryPrize}
            </p>
            <p>
              <span>Participants: </span> {participants}
            </p>
            <p>
              <span>Start Date:</span> {startDate}
            </p>
            <p>
              <span>End Date:</span> {endDate}
            </p>
            <p>
              <span>Age Group:</span> {ageGroup}
            </p>
            <h5>
              <a href="contestsTC.html" target="_blank">
                Terms & Conditions
              </a>
            </h5>
            <Button
              onClick={() => this.props.onClick(this.state.contest)}
              type={this.state.type}></Button>
          </div>
        </div>
      </>
    )

    return str
  }
}
