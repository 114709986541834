import "./CouponCode.css"
import QRCode from "react-qr-code"
import React from "react"
import CouponModel from "../../model/CouponModel"
import { EventEmitter } from "./../../events"
import ConfigModel from "../../model/ConfigModel"

export default class CouponCode extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      coupon: props.coupon,
      generatedCoupon: undefined,
    }

    this.configModel = new ConfigModel()
    this.couponModel = new CouponModel()

    this.generateCoupon = this.generateCoupon.bind(this)
  }

  componentDidMount() {
    this.generateCoupon()
  }

  async generateCoupon() {
    let gCoupon = await this.couponModel.GenerateCoupon(this.state.coupon.id)
    let expDt = new Date(gCoupon.expiration_date)
    gCoupon.expiration_date = `${expDt.getDate()}/${expDt.getMonth()}/${expDt.getFullYear()}`
    this.setState({ generatedCoupon: gCoupon })

    EventEmitter.dispatch("offerPurchased")
  }

  render() {
    let qr
    if (this.state.generatedCoupon !== undefined) {
      qr = (
        <>
          {/* <button id="view-code" className="view-code"><i className="fa fa-eye" aria-hidden="true"></i>VIEW CODE</button> */}
          <div id="code" className="code">
            {/* <span>HOWZZAT80</span>
                                    <button id="copy-code" className="copy-code"><i className="fa fa-clone" aria-hidden="true"></i> COPY CODE</button> */}
            <QRCode
              title={this.state.coupon.name}
              value={`${this.configModel.couponURL}?token=${this.state.generatedCoupon?.coupon_code}`}
            />
          </div>
          <div className="clearfix spacer30"></div>
          {/* <h4>We’ve also sent the coupon code to your email address.</h4> */}
          <h4>
            Please take a picture of the QR Code. You can show this QR code to
            the seller
          </h4>
          <p>Expiration Date: {this.state.generatedCoupon?.expiration_date}</p>
        </>
      )
    }

    return (
      <div className="content step4" id="redeem-step4">
        <img
          className="coupon-image"
          src={this.state.coupon.image_url}
          alt=""
        />
        <span>{this.state.coupon.name}</span>
        <div className="clearfix spacer30"></div>
        {qr}
        <div className="clearfix"></div>
        <button id="go-store" className="btns" onClick={this.props.onHome}>
          HOME
        </button>
      </div>
    )
  }
}
