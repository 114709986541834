import UserModel from "../../model/UserModel"
import "./Profile.css"
import React from "react"
import { EventEmitter } from "../../events"
import RedeemedCoupons from "./RedeemedCoupons/RedeemedCoupons"
import ProfileSlider from "./ProfileSlider/ProfileSlider"

// Image imports
import closeBtn from "../../assets/img/close-btn.svg"
import profileAvatar from "../../assets/img/profile-avatar.jpg"

export default class Profile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialized: props.opened,
      user: undefined,
      innovations: [],
      index: 0,
    }

    this.userModel = new UserModel()

    this.refreshUser = this.refreshUser.bind(this)
  }

  componentDidMount() {
    // this.refreshUser()

    EventEmitter.subscribe("profileUpdated", () => {
      this.refreshUser()
    })
  }

  static getDerivedStateFromProps(props, state) {
    if (props.opened !== state.initialized) {
      if (!props.opened) {
        return {
          ...state,
          initialized: props.opened,
          innovations: [],
          index: 0,
        }
      }
      // Change in props
      return {
        ...state,
        initialized: props.opened,
      }
    } else {
      return {
        ...state,
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.opened && this.props.opened !== prevProps.opened) {
      this.refreshUser()
    }
  }

  async refreshUser() {
    let user
    user = await this.userModel.GetUser()
    this.setState({ user: user })
  }

  calculateAge(dob) {
    console.log("--------------------------------------")
    console.log("Calculate age called with dob: ", dob)
    let ageDifMs = Date.now() - new Date(dob)
    let ageDate = new Date(ageDifMs) // milliseconds from epoch
    console.log("ageDifMs: ", ageDifMs)
    console.log("ageDate: ", ageDate)
    console.log("ageDate.getUTCFullYear(): ", ageDate.getUTCFullYear())
    console.log("--------------------------------------")
    return isNaN(Math.abs(ageDate.getUTCFullYear() - 1970))
      ? 0
      : Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  render() {
    return (
      <div
        className="modal fade"
        id="Profile"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="Profile"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content profile">
            <a
              onClick={this.props.onClose}
              href="!#"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close">
              <img alt="" src={closeBtn} />
            </a>
            <div className="pop-banner profile">
              <h1>PROFILE</h1>
            </div>
            <div className="modal-body">
              <div className="content">
                {(() => {
                  if (this.state.initialized) {
                    let age = this.calculateAge(
                      this.state.user?.data?.student_dob
                    )
                    return (
                      <>
                        <div className="left">
                          <div className="l-l">
                            <img alt="" src={profileAvatar} />
                          </div>
                          <div className="l-r">
                            <h1>{this.state.user?.data?.name}</h1>
                            <span>{age} Years</span>
                            <div className="clearfix"></div>
                            <ul>
                              <li>
                                <h5>School Name</h5>
                                <p>{this.state.user?.data?.school_name}</p>
                              </li>
                              <li>
                                <h5>Class & Division</h5>
                                <p>{this.state.user?.data?.class_name}</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="right">
                          <ul>
                            <li>
                              <h5>
                                All Time Rank:{" "}
                                <span className="wins">
                                  {
                                    this.state.user?.data?.userProfileStatics
                                      ?.all_time_rank
                                  }
                                </span>
                              </h5>
                            </li>
                            <li>
                              <h5>
                                Coins Earned:{" "}
                                <span className="point">
                                  {
                                    this.state.user?.data?.userProfileStatics
                                      ?.coins
                                  }
                                </span>
                              </h5>
                            </li>
                            <li>
                              <h5>
                                All Time Score:{" "}
                                <span className="score">
                                  {
                                    this.state.user?.data?.userProfileStatics
                                      ?.credits
                                  }
                                </span>
                              </h5>
                            </li>
                          </ul>
                        </div>
                        <div className="clearfix"></div>
                        <ProfileSlider
                          passedProfiles={this.state.innovations}
                        />

                        <RedeemedCoupons></RedeemedCoupons>
                      </>
                    )
                  }
                })()}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
