import "./Coupon.css"
import React from "react"
import CouponModel from "./../../model/CouponModel"

export default class Coupon extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      coupons: [],
      selectedCoupon: undefined,
    }
    this.couponModel = new CouponModel()

    this.setCurrentCoupon = this.setCurrentCoupon.bind(this)
    this.goNext = this.goNext.bind(this)
  }

  componentDidMount() {
    this.getCoupons()
  }

  async getCoupons() {
    let coupons = await this.couponModel.GetOffers()
    this.setState({
      coupons: coupons,
      selectedCoupon: undefined,
    })
  }

  setCurrentCoupon(coupon) {
    this.setState({
      selectedCoupon: coupon,
    })
  }

  goNext() {
    this.props.onNext(this.state.selectedCoupon)
  }

  render() {
    let couponsEmpty = true
    let couponsList = []
    for (let coupon of this.state.coupons) {
      couponsList.push(
        <li
          key={`couponKey-${coupon.id}`}
          onClick={() => {
            this.setCurrentCoupon(coupon)
          }}>
          <input
            type="radio"
            name="company"
            id={`coupon-${coupon.id}`}
            className="input-hidden"
          />
          <label htmlFor={`coupon-${coupon.id}`}>
            <img src={coupon.image_url} alt="" />
          </label>
        </li>
      )
      couponsEmpty = false
    }

    if (couponsEmpty) {
      couponsList.push(
        <li key={`couponKey-0`}>
          <label>
            <h5>No coupons available</h5>
          </label>
        </li>
      )
    }

    return (
      <div className="content step2" id="redeem-step2">
        <h4>Choose a Coupon</h4>
        <ul>{couponsList}</ul>
        {(() => {
          if (!couponsEmpty) {
            return (
              <button
                id="proceed2"
                className="btns"
                onClick={this.goNext}
                disabled={this.state.selectedCoupon === undefined}>
                Proceed
              </button>
            )
          }
        })()}
      </div>
    )
  }
}
