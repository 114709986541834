import CountryModel from "../../model/CountryModel"
import "./SafetyTips.css"
import React from "react"

// Image imports
import eGuideImg from "../../assets/img/eguide-img.svg"
import viewVideoImg from "../../assets/img/view-video-img.svg"

export default class SafetyTips extends React.Component {
  constructor(param) {
    super(param)
    this.state = {
      contest: param.contest,
      type: param.type,
      isArabic: false,
    }

    this.countryModel = new CountryModel()

    this.getCurrentCountryDetails = this.getCurrentCountryDetails.bind(this)
  }

  componentDidMount() {
    this.getCurrentCountryDetails()
  }

  componentWillUnmount() {
    this.setState({})
  }

  async getCurrentCountryDetails() {
    let countryDetails = await this.countryModel.GetCurrCountryDetails()

    let languages = countryDetails.languages
    if (languages.search("ar-AE") !== -1) {
      this.setState({ isArabic: true })
    }
  }

  render() {
    let str = (
      <>
        <div className="left">
          <h1>E-GUIDE</h1>
          {this.state.isArabic ? (
            <a
              href="https://esafetynewsletter.com/uploads/The_Digital_Landscape_Arabic/"
              target="_blank"
              rel="noreferrer">
              <img alt="" src={eGuideImg} />
            </a>
          ) : (
            <a
              href="https://esafetynewsletter.com/uploads/The_Digital_Landscape_English/#p=6"
              target="_blank"
              rel="noreferrer">
              <img alt="" src={eGuideImg} />
            </a>
          )}
        </div>
        <div className="right">
          <h1>VIEW VIDEO</h1>
          <a
            href="https://www.youtube.com/watch?v=ZT5YB9IxCLg&t=1s"
            target="_blank"
            rel="noreferrer">
            <img alt="" src={viewVideoImg} />
          </a>
        </div>
      </>
    )

    return str
  }
}
