import Coupon from "../coupon/Coupon"
import CouponCode from "../couponCode/CouponCode"
import CouponsTC from "../couponsTC/CouponsTC"
import Redeem from "../redeem/Redeem"
import "./RedeemModule.css"
import React from "react"

// Image imports
import closeBtnImg from "../../assets/img/close-btn.svg"

export default class RedeemModule extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialized: false,
      currentScreen: 1,
      coupon: undefined,
    }

    this.goToNext = this.goToNext.bind(this)
    this.goBack = this.goBack.bind(this)
    this.goHome = this.goHome.bind(this)
    this.resetState = this.resetState.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.opened !== state.initialized) {
      // Change in props
      return {
        ...state,
        initialized: props.opened,
      }
    } else {
      return {
        ...state,
      }
    }
  }

  goToNext(coupon) {
    let nextScreen = this.state.currentScreen + 1
    this.setState({ currentScreen: nextScreen, coupon: coupon })
  }

  goBack(coupon) {
    let prevScreen = this.state.currentScreen - 1
    this.setState({ currentScreen: prevScreen, coupon: coupon })
  }

  goHome() {
    let homeScreen = 1
    this.setState({ currentScreen: homeScreen })
  }

  resetState() {
    this.setState({
      initialized: false,
      currentScreen: 1,
      coupon: undefined,
    })

    this.props.onClose()
  }

  render() {
    return (
      <div
        className="modal fade"
        id="PointsEarned"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="PointsEarned"
        aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content points-earned">
            <a
              onClick={this.resetState}
              href="!#"
              className="close-btn"
              data-bs-dismiss="modal"
              aria-label="Close">
              <img src={closeBtnImg} alt="" />
            </a>
            <div className="pop-banner points-earned">
              <h1>REDEEM</h1>
            </div>
            <div className="modal-body">
              {(() => {
                if (this.state.initialized) {
                  if (this.state.currentScreen === 1) {
                    return <Redeem onNext={this.goToNext} />
                  } else if (this.state.currentScreen === 2) {
                    return <Coupon onNext={this.goToNext} />
                  } else if (this.state.currentScreen === 3) {
                    return (
                      <CouponsTC
                        onNext={this.goToNext}
                        onBack={this.goBack}
                        coupon={this.state.coupon}
                      />
                    )
                  } else if (this.state.currentScreen === 4) {
                    return (
                      <CouponCode
                        coupon={this.state.coupon}
                        onHome={this.goHome}
                      />
                    )
                  }
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
