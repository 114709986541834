import "./PlayLearnEarn.css"
import React from "react"

// Image imports
import puzzle1 from "../../assets/img/puzzle1.jpg"
import puzzle2 from "../../assets/img/puzzle2.jpg"
import puzzle3 from "../../assets/img/puzzle3.jpg"
import puzzle4 from "../../assets/img/puzzle4.jpg"
import puzzle5 from "../../assets/img/puzzle5.jpg"
import puzzle6 from "../../assets/img/puzzle6.jpg"
import puzzle7 from "../../assets/img/puzzle7.jpg"

export default class PlayLearnEarn extends React.Component {
  constructor(param) {
    super(param)
    this.state = {
      contest: param.contest,
      type: param.type,
    }
  }

  componentWillUnmount() {
    this.setState({})
  }

  render() {
    let str = (
      <>
        <div className="left play-learn">
          <ul>
            <a
              href="https://scratch.mit.edu/projects/120738613/"
              target="_blank"
              rel="noreferrer">
              <li>
                <img alt="" src={puzzle1} title="Dodge" />
                <p title="Dodge">Dodge</p>
              </li>
            </a>
            <a
              href="https://www.jigsawplanet.com/?rc=play&pid=067f451c74d7"
              target="_blank"
              rel="noreferrer">
              <li>
                <img alt="" src={puzzle2} title="Jig Saw" />
                <p title="Jig Saw">Jig Saw</p>
              </li>
            </a>
            <a
              href="https://wordwall.net/resource/3045686"
              target="_blank"
              rel="noreferrer">
              <li>
                <img alt="" src={puzzle3} title="Cross word" />
                <p title="Cross word">Cross word</p>
              </li>
            </a>
            <a
              href="https://wordwall.net/resource/4325178"
              target="_blank"
              rel="noreferrer">
              <li>
                <img alt="" src={puzzle4} title="Matching pair" />
                <p title="Matching pair">Matching pair</p>
              </li>
            </a>
            <a
              href="https://wordwall.net/resource/22869075"
              target="_blank"
              rel="noreferrer">
              <li>
                <img alt="" src={puzzle5} title="Flash Cards" />
                <p title="Flash Cards">Flash Cards</p>
              </li>
            </a>
            <a
              href="https://wordwall.net/about/template/complete-the-sentence"
              target="_blank"
              rel="noreferrer">
              <li>
                <img alt="" src={puzzle6} title="Complete the sentence" />
                <p title="Complete the sentence">Complete the sentence</p>
              </li>
            </a>
            <a
              href="https://scratch.mit.edu/projects/17828009/"
              target="_blank"
              rel="noreferrer">
              <li>
                <img alt="" src={puzzle7} title="Typing Master" />
                <p title="Typing Master">Typing Master</p>
              </li>
            </a>
          </ul>
        </div>
      </>
    )

    return str
  }
}
