import "./AgeEntry.css"
import React from "react"

import UserModel from "../../../model/UserModel"

export default class AgeEntry extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            studentDOB: "",
            studentDOBError: "",
            schoolName: "",
            schoolNameError: "",
            className: "",
            classNameError: "",
            hasError: false
        }

        this.userModel = new UserModel()

        this.handleOnChange = this.handleOnChange.bind(this)
        this.submitProfile = this.submitProfile.bind(this)
        this.validateForm = this.validateForm.bind(this)
    }

    componentDidMount() {
        let user = this.userModel.GetUserFromLocal()
        this.setState({
            studentDOB: user.data.student_dob,
            schoolName: user.data.school_name,
            className: user.data.class_name
        })
    }

    validateForm() {
        let studentDOB = this.state.studentDOB
        let schoolName = this.state.schoolName
        let className = this.state.className

        let today = new Date()

        let hasError = false
        let err = ""
        if (studentDOB === null || studentDOB > today) {
            err = "Invalid Date of Birth"
            hasError = true
            this.setState({ studentDOBError: err, hasError })
        }
        if (schoolName === "" || schoolName.length > 255) {
            err = "Invalid School Name"
            hasError = true
            this.setState({ schoolNameError: err, hasError })
        }
        if (className === "" || className.length > 255) {
            err = "Invalid Class Name"
            hasError = true
            this.setState({ classNameError: err, hasError })
        }

        return hasError
    }

    handleOnChange(event) {
        let today = new Date()

        let { name, value } = event.target
        let hasError = false
        if (name === "studentDOB") {
            let err = ""
            if (value === null || value > today) {
                err = "Invalid Date of Birth"
                hasError = true
            }
            this.setState({ studentDOB: value, studentDOBError: err, hasError })
        }
        if (name === "schoolName") {
            let err = ""
            if (value === "" || value.length > 255) {
                err = "Invalid School Name"
                hasError = true
            }
            this.setState({ schoolName: value, schoolNameError: err, hasError })
        }
        if (name === "className") {
            let err = ""
            if (value === "" || value.length > 255) {
                err = "Invalid Class Name"
                hasError = true
            }
            this.setState({ className: value, classNameError: err, hasError })
        }
    }

    async submitProfile(event) {
        event.preventDefault()
        let hasError = this.validateForm()

        if (!hasError) {
            await this.userModel.UpdateProfile(
                this.state.studentDOB,
                this.state.schoolName,
                this.state.className
            )

            this.props.onAgeEntered()
        }
    }

    render() {
        let studentDOB = this.state.studentDOB
        if (studentDOB === null) {
            studentDOB = ""
        }

        return (
            <>
                <form id="login-form" onSubmit={this.submitProfile}>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="studentDOB">Date of Birth</label>
                                    <input type="date" className="form-control" onChange={this.handleOnChange}
                                        name="studentDOB" id="studentDOB" placeholder="Date of Birth" defaultValue={studentDOB} />
                                    <small className="form-text text-danger">{this.state.studentDOBError}</small>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="schoolName">School Name</label>
                                    <input type="text" className="form-control" name="schoolName" id="schoolName" onChange={this.handleOnChange}
                                        placeholder="School Name" defaultValue={this.state.schoolName} />
                                    <small className="form-text text-danger">{this.state.schoolNameError}</small>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="className">Class & Division</label>
                                    <input type="text" className="form-control" name="className" id="className" onChange={this.handleOnChange}
                                        placeholder="Class & Division" defaultValue={this.state.className} />
                                    <small className="form-text text-danger">{this.state.classNameError}</small>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {/* <div id="login-submit" className="btns" >Submit</div> */}
                                <button type="submit" className="btns" disabled={this.state.hasError}>Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}