import "./StartPage.css"
import React from "react"
import AgeEntry from "./ageEntry/AgeEntry"
import Help from "./help/help"

// Video Imports
import stogoStoryVideo from "../../assets/videos/stogostory.mp4"

export default class StartPage extends React.Component {
  constructor(props) {
    super(props)
    this.vidRef = React.createRef()

    this.state = {
      screen: 1,
    }

    this.changeState = this.changeState.bind(this)
    this.playVideo = this.playVideo.bind(this)
    this.afterVideoStop = this.afterVideoStop.bind(this)
  }

  changeState() {
    this.setState(
      (prevState) => {
        return {
          screen: prevState.screen + 1,
        }
      },
      () => {
        if (this.state.screen === 2) {
          this.playVideo()
        }
      }
    )
  }

  playVideo() {
    this.vidRef.current.play()
  }

  afterVideoStop() {
    this.changeState()
  }

  render() {
    return (
      <>
        <div className="content">
          <div style={{ display: this.state.screen === 1 ? "block" : "none" }}>
            <AgeEntry onAgeEntered={this.changeState}></AgeEntry>
          </div>
          {/* <!-- how to play section --> */}
          <div
            id="introVideo"
            style={{ display: this.state.screen === 2 ? "block" : "none" }}>
            <video width="100%" ref={this.vidRef} onEnded={this.afterVideoStop}>
              <source src={stogoStoryVideo} type="video/mp4" />
            </video>

            <button
              type="button"
              className="btn btn-primary skip-video-button"
              onClick={this.afterVideoStop}>
              Skip Story
            </button>
          </div>
          <div
            id="howtoplay"
            style={{ display: this.state.screen === 3 ? "block" : "none" }}>
            <Help></Help>
            <div
              id="login-submit"
              className="btns"
              onClick={() => this.props.onProceed()}>
              Proceed
            </div>
          </div>
        </div>
      </>
    )
  }
}
