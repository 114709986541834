import "./ReviewAnswers.css"
import React from "react"
import ReviewAnswer from "./reviewAnswer/ReviewAnswer"

export default class ReviewAnswers extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            questions: props.summary.questions,
            contest: props.contest,
            currentPosition: 0,
        }

        this.goBack = this.goBack.bind(this)
        this.goForward = this.goForward.bind(this)
    }

    goBack() {
        let currentPosition = this.state.currentPosition
        if (this.state.currentPosition > 0) {
            currentPosition--
        }
        this.setState({ currentPosition: currentPosition })
    }

    goForward() {
        let final = this.state.questions.length - 1
        let currentPosition = this.state.currentPosition
        if (currentPosition < final) {
            currentPosition++

            this.setState({ currentPosition: currentPosition })
        } else {
            this.props.onShowResult(null)
        }
    }

    componentWillUnmount() {
        this.setState({})
    }

    render() {
        return <ReviewAnswer contest={this.state.contest}
            question={this.state.questions[this.state.currentPosition]}
            questionPosition={this.state.currentPosition}
            onPrevious={this.goBack}
            onNext={this.goForward}></ReviewAnswer>
    }
}