import ContestsModule from "../contestsModule/ContestsModule"
import "./Played.css"
import React from "react"

// Image imports
import playNowButt from "../../assets/img/play-now-butt.svg"

export default class Played extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            contest: props.contest,
            type: props.type,
            isPlayingContest: false,
            recent_players: props.recentPlayers
        }

        this.toggleContest = this.toggleContest.bind(this)
    }

    componentWillUnmount() {
        this.setState({})
    }

    toggleContest() {
        this.setState({ isPlayingContest: !this.state.isPlayingContest })
    }

    static getDerivedStateFromProps(props, state) {
        if (props !== state) {
            //Change in props 
            return {
                ...state,
                recent_players: props.recentPlayers,
                contest: props.contest,
                type: props.type,
            }
        }
    }

    render() {
        let content
        if (!this.state.isPlayingContest) {
            let players = []
            let count = 0
            for (let recent_player of this.state.recent_players) {
                count++
                if (count > 3) {
                    break
                }
                players.push(
                    <li key={`${recent_player.user}-${count}`}>{recent_player.user} <small>played {recent_player.contest} on {recent_player.played_on}</small></li>
                )
            }

            content = (
                <>
                    <div className="col-md-12">
                        <div className="button button-small" onClick={this.toggleContest}>
                            <img alt="" src={playNowButt} />
                        </div>
                        <ul className="mt-3">
                            {players}
                        </ul>
                    </div>
                </>
            )
        } else {
            content = (
                <>
                    <ContestsModule onGoBack={this.toggleContest}></ContestsModule>
                </>
            )
        }

        return content
    }
}