import React from "react"

// Video Imports
import stogoStory from "./../../assets/videos/stogostory.mp4"

export default class IntroVideoPlayer extends React.Component {
  /*
  constructor(props) {
    super(props)
    this.vidRef = React.createRef()
}
stopVideo() {
  this.vidRef.current.stopVideo();
}*/

  render() {
    return (
      <div className="content" id="introVideo">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content play-now">
            <video
              width="100%"
              height="100%"
              controls
              autoPlay
              ref={this.vidRef}>
              <source src={stogoStory} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    )
  }
}
