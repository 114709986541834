import React from "react"
import Swiper from "swiper"
import { Navigation, Autoplay } from 'swiper/modules'
import "swiper/css"
import 'swiper/css/navigation'
import "./RedeemedCoupons.css"
import CouponModel from "../../../model/CouponModel"
import QRCode from "react-qr-code"
import { EventEmitter } from "../../../events"

export default class RedeemedCoupons extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            swiper: undefined,
            gCoupons: []
        }
        this.couponModel = new CouponModel()

        this.initSwiper = this.initSwiper.bind(this)
    }

    componentWillUnmount() {
        // this.state.swiper.destroy()
    }

    componentDidMount() {
        this.initSwiper()
        this.fetchGeneratedCoupons()

        EventEmitter.subscribe('redeemedCouponsUpdated', () => {
            this.fetchGeneratedCoupons()
        })
    }

    initSwiper() {
        let swiper = new Swiper('.redeemedCoupons', {
            modules: [Navigation, Autoplay],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 3, // Display 3 slides at a time
            // spaceBetween: 10, // Adjust the space between slides as needed
            // Add other Swiper options as needed
            spaceBetween: 35,
            breakpoints: {
                // Responsive breakpoints
                320: {
                    slidesPerView: 1,
                },
                768: {
                    slidesPerView: 2,
                },
                1199: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 3,
                }
            }
        })

        this.setState({ swiper: swiper })
    }

    async fetchGeneratedCoupons() {
        let gc = await this.couponModel.GetGeneratedCoupons()
        this.setState({ gCoupons: gc })
    }

    render() {
        let coupons = []
        for (let coupon of this.state.gCoupons) {
            let d = new Date(coupon.expire_date)
            let expDate = `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`
            coupons.push(
                <div key={`coupon-${coupon.id}`} className="swiper-slide">
                    <div className="card mb-3">
                        <img className="card-img-top" src={coupon.image} alt="Card cap" />
                        <div className="card-body">
                            <h5 className="card-title">{coupon.offer_name}</h5>
                            <p className="card-text">
                                Expiring On: {expDate}
                                <br></br>
                                Status: {`${coupon.is_used === "0" ? "Active" : "Used"}`}
                                <br></br>
                                <QRCode
                                    fgColor="#252763"
                                    title={coupon.offer_name}
                                    value={coupon.coupon_code}
                                />
                            </p>
                            {/* <button type="button" className="play-btn">Show Code</button> */}
                        </div>
                    </div>
                </div>
            )
        }

        let data = ""
        if (this.state.gCoupons.length > 0) {
            data = (
                <div className="coupon-box">
                    <h1>Redeemed Coupons</h1>

                    <div className="box">
                        <div className="redeemedCoupons">
                            <div className="swiper-wrapper">
                                {coupons}
                            </div>
                            <div className="swiper-button-next"></div>
                            <div className="swiper-button-prev"></div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            data
        )
    }
}