import "./PublicProfile.css"
import React from "react"
import { useParams } from "react-router-dom"
import UserModel from "../../model/UserModel"
import StudentSubmissionModel from "../../model/StudentSubmissionModel"
import PublicProfileSlider from "./PublicProfileSlider/PublicProfileSlider"
import { Link } from "react-router-dom"

// Image imports
import profileAvatarImg from "../../assets/img/profile-avatar.jpg"
import backtowImg from "../../assets/img/backtow.svg"

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />
}

class PublicProfile extends React.Component {
  constructor(props) {
    super(props)

    const urlParams = new URLSearchParams(window.location.search)
    const token = urlParams.get("token")

    this.state = {
      token: token,
      userId: undefined,
      user: undefined,
      innovations: [],
      index: 0,
      initialized: false,
    }

    this.userModel = new UserModel()
    this.submissionModel = new StudentSubmissionModel()

    this.fetchProfileData = this.fetchProfileData.bind(this)
    this.getAuthUserData = this.getAuthUserData.bind(this)
  }

  async getAuthUserData() {
    let userData = await this.userModel.AuthenticateUser(this.state.token)
    if (userData) {
      this.fetchProfileData()
    }
  }

  async fetchProfileData() {
    let user = await this.userModel.FetchUserProfile(this.state.userId)

    this.setState({
      user: user,
      initialized: true,
    })
  }

  componentDidMount() {
    this.setState(
      {
        userId: this.props.params.id,
      },
      () => {
        if (this.state.token) {
          this.getAuthUserData()
        } else {
          this.fetchProfileData()
        }
      }
    )
  }

  calculateAge(dob) {
    let ageDifMs = Date.now() - new Date(dob)
    let ageDate = new Date(ageDifMs) // milliseconds from epoch
    return isNaN(Math.abs(ageDate.getUTCFullYear() - 1970))
      ? 0
      : Math.abs(ageDate.getUTCFullYear() - 1970)
  }

  render() {
    let age = this.calculateAge(this.state.user?.student_dob)
    return (
      <>
        <div className="container boxes profile">
          <div className="row">
            <div className="col-md-12">
              <div className="card mt-5">
                <div className="card-body content">
                  {(() => {
                    if (this.state.initialized) {
                      return (
                        <>
                          <div className="left">
                            <div className="l-l">
                              <img alt="User Icon" src={profileAvatarImg} />
                            </div>
                            <div className="l-r">
                              <h1>{this.state.user?.name}</h1>
                              <span>{age} Years</span>
                              <div className="clearfix"></div>
                              <ul>
                                <li>
                                  <h5>School Name</h5>
                                  <p>{this.state.user?.school_name}</p>
                                </li>
                                <li>
                                  <h5>Class & Division</h5>
                                  <p>{this.state.user?.class_name}</p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="right">
                            <ul>
                              <li>
                                <h5>
                                  All Time Rank:{" "}
                                  <span className="wins">
                                    {this.state.user?.all_time_rank}
                                  </span>
                                </h5>
                              </li>
                              <li>
                                <h5>
                                  Coins Earned:{" "}
                                  <span className="point">
                                    {this.state.user?.coins}
                                  </span>
                                </h5>
                              </li>
                              <li>
                                <h5>
                                  All Time Score:{" "}
                                  <span className="score">
                                    {this.state.user?.credits}
                                  </span>
                                </h5>
                              </li>
                            </ul>
                          </div>
                          <div className="clearfix"></div>
                          <PublicProfileSlider
                            passedProfiles={this.state.innovations}
                            userId={this.state.userId}
                          />
                        </>
                      )
                    }
                  })()}

                  <Link to={`/`}>
                    <img
                      alt="Back to the Stogo World"
                      title="Back to the Stogo World"
                      className="bakto"
                      src={backtowImg}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withParams(PublicProfile)
