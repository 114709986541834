import UserModel from "../../model/UserModel"
import "./Redeem.css"
import React from "react"

// Image imports
import pointsCoinImg from "../../assets/img/points-coin.svg"

export default class Redeem extends React.Component {
  constructor(param) {
    super(param)
    this.state = {
      contest: param.contest,
      type: param.type,
      user: {},
    }

    this.userModel = new UserModel()
  }

  componentDidMount() {
    let user = this.userModel.GetUserFromLocal()
    this.setState({ user: user })
  }

  componentWillUnmount() {
    this.setState({})
  }

  render() {
    let str = (
      <>
        <div className="content step1" id="redeem-step1">
          <div className="left">
            <h5>Points Earned</h5>
            <div className="point-box">
              {this.state.user.data?.userProfileStatics?.coins}
            </div>
          </div>
          <div className="right space">
            <img src={pointsCoinImg} alt="" />
          </div>
          <div className="row">
            <div className="col-md-12">
              <button
                id="redeem-points"
                className="btns"
                onClick={this.props.onNext}>
                REDEEM POINTS
              </button>
              {/* <button id="refer-earn" className="btns">Refer & Earn</button> */}
            </div>
          </div>
        </div>
      </>
    )

    return str
  }
}
